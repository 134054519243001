@tailwind base;
@tailwind components;
@tailwind utilities;

input ~ .dot {
  transform: translateX(0%);
  opacity: 50%;
}

input:checked ~ .dot {
  transform: translateX(100%);
}

input ~ .js-bg {
  background-image: url(./img/elyos.png);
  background-position: 50%;
  background-size: cover;
}

input:checked ~ .js-bg {
  background-image: url(./img/asmo.png);
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.switch-enter {
  opacity: 0.01;
}
.switch-enter.switch-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.switch-leave {
  opacity: 1;
}
.switch-leave.switch-leave-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#classic {
  text-align: left;
  color: white;
  font-family: "Noto Sans", sans-serif;
}

#classic p {
  font-family: "Noto Sans", sans-serif;
}

#classic .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 331px;
  height: 75px;
  max-width: 100%;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
  border: 0;
  opacity: 0.9;
  border-radius: 0;
}

#classic .btn:hover {
  opacity: 1;
}

#classic .btn.btn-classes-tab {
  text-indent: -999999px;
  overflow: hidden;
  font-size: 24px;
  margin-bottom: 5px;
  opacity: 0.6;
}

#classic .btn.btn-classes-tab.tab-1 {
  width: 219px;
  height: 44px;
  background: url(./img/AN-Classic-classes-btn-asmo_a.png) no-repeat center
    center/contain;
}

@media (max-width: 768px) {
  #classic .btn.btn-classes-tab.tab-1 {
    width: 100px;
    height: 32px;
  }
}

#classic .btn.btn-classes-tab.tab-2 {
  width: 146px;
  height: 44px;
  background: url(./img/AN-Classic-classes-btn-elyos_a.png) no-repeat center
    center/contain;
}

@media (max-width: 768px) {
  #classic .btn.btn-classes-tab.tab-2 {
    width: 80px;
    height: 32px;
  }
}

#classic .btn.btn-classes-tab:hover,
#classic .btn.btn-classes-tab.active {
  opacity: 1;
}

#classic .classic-inner {
  width: 1920px;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
}

@media (min-width: 1921px) {
  #classic .classic-inner {
    width: 100%;
  }
}

#classic .classic-classes {
  position: relative;
  min-height: 100vh;
}

@media (max-width: 1024px) {
  #classic .classic-classes {
    min-height: 1000px;
  }
}

#classic .classic-classes:after {
  content: " ";
  opacity: 0;
  pointer-events: none;
  background: url(./img/AN-Classic-bg_06b.png) no-repeat center;
  background-attachment: fixed;
}

#classic .classic-classes.Asmodian {
  background: url(./img/AN-Classic-bg_06a.png) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
}

#classic .classic-classes.Elyos {
  background: url(./img/AN-Classic-bg_06b.png) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
}

#classic .classic-classes-left {
  position: absolute;
  bottom: 100px;
  left: 40px;
  width: 400px;
  max-width: 100%;
  text-align: left;
  z-index: 1;
}

#classic .classic-classes-left h2 {
  text-transform: uppercase;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-size: 90px;
  margin: 0;
}

@media (max-width: 1024px) {
  #classic .classic-classes-left h2 {
    font-size: 50px;
  }
}

#classic .classic-classes-tabs {
  padding-left: 120px;
  padding-top: 30px;
  min-height: 140px;
}

#classic .classic-classes-tabs.Asmodian {
  background: url(./img/AN-Classic-classes-crest_Asmodian.png) no-repeat left
    top/104px auto;
}

#classic .classic-classes-tabs.Elyos {
  background: url(./img/AN-Classic-classes-crest_Elyos.png) no-repeat left
    top/133px auto;
}

#classic .classic-classes-characters {
  width: 1400px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  display: none;
}

@media (max-width: 1024px) {
  #classic .classic-classes-characters {
    top: 60%;
    width: 700px;
    height: 500px;
  }
}

#classic .classic-classes-characters.active {
  display: flex;
}

#classic .classic-classes-character {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  margin: 0 -100px;
}

#classic .Asmodian .classic-classes-character.character-1 {
  background-image: url(./img/AN-Classic-char-asmo_01.png);
  width: 408px;
  height: 600px;
}

#classic .Asmodian .classic-classes-character.character-1:after {
  background-image: url(./img/AN-Classic-char-elyos_01.png);
  opacity: 0;
  pointer-events: none;
  content: " ";
}

#classic .Asmodian .classic-classes-character.character-2 {
  background-image: url(./img/AN-Classic-char-asmo_02.png);
  width: 510px;
  height: 736px;
  position: relative;
  z-index: 1;
}

#classic .Asmodian .classic-classes-character.character-2:after {
  background-image: url(./img/AN-Classic-char-elyos_02.png);
  opacity: 0;
  pointer-events: none;
  content: " ";
}

#classic .Asmodian .classic-classes-character.character-3 {
  background-image: url(./img/AN-Classic-char-asmo_03.png);
  width: 788px;
  height: 702px;
}

#classic .Asmodian .classic-classes-character.character-3:after {
  background-image: url(./img/AN-Classic-char-elyos_03.png);
  opacity: 0;
  pointer-events: none;
  content: " ";
}

#classic .Asmodian .classic-classes-character.character-4 {
  background-image: url(./img/AN-Classic-char-asmo_04.png);
  width: 492px;
  height: 698px;
  z-index: 1;
}

#classic .Asmodian .classic-classes-character.character-4:after {
  background-image: url(./img/AN-Classic-char-elyos_04.png);
  opacity: 0;
  pointer-events: none;
  content: " ";
}

#classic .Elyos .classic-classes-character {
  margin: 0 -150px;
}

#classic .Elyos .classic-classes-character.character-1 {
  background-image: url(./img/AN-Classic-char-elyos_01.png);
  width: 551px;
  height: 647px;
  position: relative;
  z-index: 1;
}

#classic .Elyos .classic-classes-character.character-2 {
  background-image: url(./img/AN-Classic-char-elyos_02.png);
  width: 859px;
  height: 729px;
}

#classic .Elyos .classic-classes-character.character-3 {
  background-image: url(./img/AN-Classic-char-elyos_03.png);
  width: 663px;
  height: 574px;
  position: relative;
  z-index: 1;
}

#classic .Elyos .classic-classes-character.character-4 {
  background-image: url(./img/AN-Classic-char-elyos_04.png);
  width: 550px;
  height: 458px;
}

@keyframes animate-slide-right {
  from {
    transform: translateX(-20%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.animate__slide-right {
  animation: animate-slide-right 0.75s;
  animation-timing-function: ease;
}

@keyframes animate-slide-left {
  from {
    transform: translateX(20%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.animate__slide-left {
  animation: animate-slide-left 0.75s;
  animation-timing-function: ease;
}
